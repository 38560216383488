import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Bio from '../components/Bio';
import './styles/index.scss';
import PostList from '../components/PostList';

export interface IndexPageProps {
  path: string;
  location: any;
  data: any;
}

const isBrowser = typeof window !== "undefined"

const IndexPage = (props: IndexPageProps) => {
  const { data } = props;
  const posts = data.allMarkdownRemark.edges;
  //const {pathname} = window.location;
  //const category = pathname.split('/').pop(); //get last item
  const {category} = props;

  //let pathname = null;
  //let category = null;
  if(isBrowser) {
    //pathname = window.location.pathname;
    //category = pathname.split('/').pop(); //get last item
  }

  
  return (
    <Layout>
      <SEO title="Home" />
      <div className="index-wrap">
        <Bio />
        <div className="index-post-list-wrap">
          <PostList posts={posts} targetCategory={category} />
          
          {posts.length < 100 ? null : (
            <div className="show-more-posts">
              <div className="show-more-btn">
                <Link to="/search">
                  <Fa icon={faSearch} />
                  <span>SHOW MORE POSTS</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {/* <span>####{pathname}###{category}##</span> */}
        </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(filter: {frontmatter: {title: {ne: ""},category:{eq: $category}}}, sort: { fields: [frontmatter___date], order: DESC }, limit: 100) {
      edges {
        node {
          excerpt(format: PLAIN)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            update(formatString: "MMM DD, YYYY")
            title
            category
            tags
          }
        }
      }
    }
  }
`;

export default IndexPage;
